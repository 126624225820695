import {
  CandidateInfo,
  Certificate,
  City,
  Interview,
  Role,
  Skill,
  Specialization,
  Education,
} from '../types';
import { getRandomColor } from '../constants/palette';
import { HIRED, INTRODUCED, OFFERED, ON_HOLD, REJECTED, SOURCED } from '../constants/statuses';
import moment from 'moment';

export const groupByStageName = (
  stages: Interview[],
  list: { [key: string]: any[] },
) => {
  return stages.reduce((group, stage) => {
    if (stage.status === OFFERED) {
      (group[OFFERED] = group[OFFERED] || []).push({
        ...stage.candidate,
        stage_name: OFFERED,
        color: getRandomColor(),
        interview_id: stage.id,
      });
    } else if (stage.status === REJECTED) {
    } else if (stage.status === HIRED) {
      (group[HIRED] = group[HIRED] || []).push({
        ...stage.candidate,
        stage_name: HIRED,
        color: getRandomColor(),
        interview_id: stage.id,
      });
    } else if ([SOURCED, INTRODUCED].includes(stage.status)) {
    } else {
      const key = stage?.current_stage?.stage_name || null;
      if (key?.toLowerCase() === '1st interview') {
        // @ts-ignore
        (group['1st Interview'] = group['1st Interview'] || []).push({
          ...stage.candidate,
          stage_name: key,
          color: getRandomColor(),
          interview_id: stage.id,
        });
      } else {
        (group['2nd+ Interview'] = group['2nd+ Interview'] || []).push({
          ...stage.candidate,
          color: getRandomColor(),
          stage_name: key,
          interview_id: stage.id,
        });
      }
    }

    return group;
  }, list);
};

export const getCandidateStatistics = (stages: Interview[]) => {
  return stages.reduce(
    (group, stage) => {
      const key = stage?.status || null;
      if (key) {
        // @ts-ignore
        group[key] = group[key] + 1 || 0;
      }
      group.sum += 1;

      return group;
    },
    { sum: 0, introduced: 0, 'in process': 0, hired: 0, rejected: 0 },
  );
};

export const getNameFirstLetters = (name: string) => {
  const separatedName = name?.split(' ');
  const first =
    separatedName && separatedName.length ? separatedName[0][0] : 'A';
  const second =
    separatedName && separatedName.length > 1 ? separatedName[1][0] : 'A';
  return (first + second).toUpperCase();
};

export const addColorToCandidate = (data: CandidateInfo[]) => {
  return data.map((item) => ({ ...item, color: getRandomColor() }));
};

export const filterSkills = (skills: Skill[], ids: string[]) => {
  return ids.map((id) => skills.find((skill) => skill.id === id)?.name);
};

export const filterRoles = (roles: Role[], ids: string[] | string) => {
  if (Array.isArray(ids)) {
    return ids.map((id) => roles.find((role) => role.id === id)?.name);
  } else {
    return roles.find((role) => role.id === ids)?.name;
  }
};

export const filterCities = (cities: City[], id: string) => {
  return cities.find((city) => city.id === id)?.name;
};

export const filterDomains = (domains: Specialization[], ids: string[]) => {
  return ids.map((id) => domains.find((domain) => domain.id === id)?.name);
};

export const filterCertificates = (
  certificates: Certificate[],
  ids: string[],
) => {
  return ids.map(
    (id) => certificates.find((certificate) => certificate.id === id)?.name,
  );
};

export const filterEducation = (education: Education[], ids: string[]) =>
  ids.map((id) => education.find((ed) => ed.id === id)?.name);

type IgetAllInfo = {
  candidates: CandidateInfo[];
  cities: City[];
  skills: Skill[];
  roles: Role[];
  domains: Specialization[];
  certificates: Certificate[];
  education: Education[];
};

// @TODO do we really need roles?
export const getAllInfo = ({
  candidates,
  cities,
  skills,
  roles,
  domains,
  certificates,
  education,
}: IgetAllInfo) => {
  return candidates.map((candidate) => {
    return {
      ...candidate,
      skills: filterSkills(skills, candidate.skills || []) as [],
      city_of_residence: filterCities(
        cities,
        candidate.city_of_residence,
      ) as string,
      specializations: filterDomains(
        domains,
        candidate.specializations || [],
      ) as [],
      certificates: filterCertificates(
        certificates,
        candidate.certificates || [],
      ) as [],
      education: filterEducation(education, candidate.education || []) as [],
      color: getRandomColor(),
    };
  });
};

export type GroupByStagesFilters = {
  jobTitle: string | null;
  companyName: string | null;
  lastUpdateBefore: Date | string | null;
  lastUpdateAfter: Date | string | null;
};

export const groupByStages = async (
  candidates: CandidateInfo[],
  candidateStageGroup: Record<string, CandidateInfo[]>,
  sourcedStageEnabled: boolean,
  showReject: boolean,
  showHired: boolean,
  filters: GroupByStagesFilters,
) => {
  // this object seeds the reduce and defines the order of stages on the kanban board:
  const stages: Record<string, CandidateInfo[]> = {
    ...(sourcedStageEnabled ? { [SOURCED]: [] } : {}),
    [ON_HOLD]: [],
    [INTRODUCED]: [],
    ...candidateStageGroup,
    [OFFERED]: [],
    ...(showReject ? { [REJECTED]: [] } : {}),
    [HIRED]: []
  };
  // group the interviews into stages and apply filtering
  return candidates.reduce((totalAcc, candidate) => {
    if (!candidate) return totalAcc;
    return candidate.interviews.reduce((candidateAcc, interview) => {
      const isBuiltinStage = [
        sourcedStageEnabled && SOURCED,
        ON_HOLD,
        INTRODUCED,
        OFFERED,
        showReject && REJECTED,
        showHired && HIRED
      ].includes(interview.status);
      const isValidInterviewStage = interview.current_stage && interview.status !== REJECTED && interview.status !== HIRED;
      if (!isBuiltinStage && !isValidInterviewStage) return candidateAcc;

      // apply any filtering specified
      if (filters.jobTitle &&
        !(interview.job.title.toLowerCase().includes(filters.jobTitle.toLowerCase()) ||
          (interview.job as any).job_code?.toLowerCase().includes(filters.jobTitle.toLowerCase()))
      ) return candidateAcc;
      if (filters.companyName && !interview.job.company.name.toLowerCase().includes(filters.companyName.toLowerCase())) return candidateAcc;
      const lastUpdate = ((filters.lastUpdateAfter || filters.lastUpdateBefore) && Math.max(...[
        interview.created_at,
        interview.updated_at,
        interview.current_stage?.updated_at, // according to type annotations, current_stage should never be undefined, but it apparently can be :/
        candidate.updated_at,
      ].map((str) => str ? moment(str).startOf('day').unix() : 0))) || 0;
      if (filters.lastUpdateAfter && (!lastUpdate || (lastUpdate < moment(filters.lastUpdateAfter).startOf('day').unix()))) return candidateAcc;
      if (filters.lastUpdateBefore && (!lastUpdate || (lastUpdate > moment(filters.lastUpdateBefore).startOf('day').unix()))) return candidateAcc;

      const key = isBuiltinStage ? interview.status : interview.current_stage.stage_name.toLowerCase();
      return { ...candidateAcc, [key]: [...candidateAcc[key], { ...candidate, interviews: [interview] }] };
    }, totalAcc);
  }, stages);
};

export const generateKey = (length: number) => {
  let result = [];
  let characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result.push(
      characters.charAt(Math.floor(Math.random() * charactersLength)),
    );
  }
  return result.join('');
};

export const orderTasks = (array: any[]) => {
  let newArray: any[] = [];
  array.forEach((e) => {
    if (e.task_type === 'Plan Interview') newArray.unshift(e);
    else newArray.push(e);
  });

  return newArray;
};

export const addDecimalPoints = (num: string) => {
  let newValue = '';
  if (num) {
    num = num.replace(/\D/g, '');
    let inputValue = num.replace('.', '').split('').reverse().join(''); // reverse
    for (let i = 0; i < inputValue.length; i++) {
      if (i % 3 === 0) {
        newValue += '.';
      }
      newValue += inputValue[i];
    }
  }

  return newValue.split('').reverse().join('').slice(0, -1);
};
